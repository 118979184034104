exports.components = {
  "component---node-modules-flogy-gatsby-theme-fgs-legal-src-pages-data-privacy-js": () => import("./../../../node_modules/@flogy/gatsby-theme-fgs-legal/src/pages/data-privacy.js" /* webpackChunkName: "component---node-modules-flogy-gatsby-theme-fgs-legal-src-pages-data-privacy-js" */),
  "component---node-modules-flogy-gatsby-theme-fgs-legal-src-pages-impressum-js": () => import("./../../../node_modules/@flogy/gatsby-theme-fgs-legal/src/pages/impressum.js" /* webpackChunkName: "component---node-modules-flogy-gatsby-theme-fgs-legal-src-pages-impressum-js" */),
  "component---src-pages-admin-tsx": () => import("./../../../src/pages/admin.tsx" /* webpackChunkName: "component---src-pages-admin-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-memorista-the-ultimate-guestbook-widget-for-website-builders-tsx": () => import("./../../../src/pages/blog/memorista-the-ultimate-guestbook-widget-for-website-builders.tsx" /* webpackChunkName: "component---src-pages-blog-memorista-the-ultimate-guestbook-widget-for-website-builders-tsx" */),
  "component---src-pages-blog-why-businesses-should-still-use-a-guestbook-on-their-website-in-2022-tsx": () => import("./../../../src/pages/blog/why-businesses-should-still-use-a-guestbook-on-their-website-in-2022.tsx" /* webpackChunkName: "component---src-pages-blog-why-businesses-should-still-use-a-guestbook-on-their-website-in-2022-tsx" */),
  "component---src-pages-guestbook-tsx": () => import("./../../../src/pages/guestbook.tsx" /* webpackChunkName: "component---src-pages-guestbook-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

