import React from 'react';
import CustomLayout from '../../../landing/components/layout';

const Layout = ({ children }) => (
  <CustomLayout>
    <div className="container">{children}</div>
  </CustomLayout>
);

export default Layout;
