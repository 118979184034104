module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-302VFD8WDW"],"gtagConfig":{"anonymize_ip":true},"pluginConfig":{"head":true,"respectDNT":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Memorista","short_name":"Memorista","start_url":"/","background_color":"#f8fcff","theme_color":"#1b90ff","display":"minimal-ui","icon":"/vercel/path0/src/gatsby/../../static/images/memorista.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9cd2ae31ecb3af8604962d19c8bd7376"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["PT Serif"]},"custom":{"families":["Inter"],"urls":["/fonts/fonts.css"]}},
    },{
      plugin: require('../node_modules/@flogy/gatsby-theme-fgs-legal/gatsby-browser.js'),
      options: {"plugins":[],"currentWebsiteUrl":"https://memorista.io","companyNominative":"Memorista","companyDative":"Memorista","companyAccusative":"Memorista","companyLocation":"Switzerland","impressum":{"path":"/legal-notice","addresses":[{"title":"Responsible for the website content","company":"Emin Khateeb","street":"Suurstoffi 20","location":"6343 Rotkreuz, Switzerland","email":"emin@emin.ch"},{"title":"","company":"Florian Gyger Software","street":"Gustav Zeiler-Ring 3","location":"5600 Lenzburg, Switzerland","email":"info@floriangyger.ch"}]},"dataPrivacy":{"path":"/data-privacy","usedFeatures":["cookies","serverLogFiles","googleAnalytics","ssl","adminPortal"],"lastChangedDate":"19.10.2022","responsiblePerson":{"nameAccusative":"Florian Gyger","email":"florian.gyger@floriangyger.ch"}},"cookieBanner":{"enable":true}},
    }]
