/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import { graphql, Link, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import logo from '../../../static/images/logo.png';
import '../../../static/styles/main.scss';
import Header from './header';

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} />
        <Helmet>
          <script src="https://unpkg.com/@memorista/client-ui@2/dist/index.bundle.js" />
        </Helmet>
        <main>{children}</main>
        <footer>
          <div className={'container'}>
            <div className={'row'}>
              <div className={'col-5'}>
                <div className={'widget__item'}>
                  <div className={'logo'}>
                    <Link to="/" title={'Memorista'}>
                      <img alt={'Memorista'} src={logo} />
                    </Link>
                  </div>

                  <div className={'about'}>
                    <p>A software as a service, created to help you capture memories from your website guests.</p>
                  </div>
                </div>
              </div>

              <div className={'col-2'}>
                <div className={'widget__item'}>
                  <div className={'links'}>
                    <h4>Memorista</h4>
                    <ul>
                      <li>
                        <Link to="/guestbook" title={'Guestbook'}>
                          Guestbook
                        </Link>
                      </li>
                      <li>
                        <Link to="/blog" title={'Blog'}>
                          Blog
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className={'col-2'}>
                <div className={'widget__item'}>
                  <div className={'links'}>
                    <h4>Support</h4>
                    <ul>
                      <li>
                        <Link to="/data-privacy" title={'Data Privacy'}>
                          Data Privacy
                        </Link>
                      </li>
                      <li>
                        <Link to="/legal-notice" title={'Legal Notice'}>
                          Legal Notice
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className={'copyright'}>
              <p>
                Memorista &copy; {new Date().getFullYear()} by{' '}
                <a href="https://emin.ch" target="_blank" rel="nofollow noopener noreferrer">
                  Emin Khateeb
                </a>{' '}
                &amp;{' '}
                <a href="https://floriangyger.ch" target="_blank" rel="nofollow noopener noreferrer">
                  Florian Gyger
                </a>
              </p>
            </div>
          </div>
        </footer>
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
