import React from 'react';
import { Link as GatsbyLink } from 'gatsby';

export const InternalLink = (props) => {
  const { href, ...otherProps } = props;
  return <GatsbyLink to={href} {...otherProps} />;
};

export const ExternalLink = (props) => {
  return <a target="_blank" rel="nofollow noopener noreferrer" {...props} />;
};

const Link = (props) => {
  const isInternalLink = props.href.startsWith('/');
  if (isInternalLink) {
    return <InternalLink style={styles.link} {...props} />;
  }
  return <ExternalLink style={styles.link} {...props} />;
};

const styles = {
  link: {
    color: '#1b90ff',
  },
};

export default Link;
