const cookieConsentStyles = {
  buttonStyle: {
    padding: '6px 12px',
    backgroundColor: '#ff9a00',
    color: '#ffffff',
    fontWeight: 'bold',
    fontSize: 16,
    borderRadius: 4,
    textAlign: 'center',
  },
};

export default cookieConsentStyles;
