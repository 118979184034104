import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { Link } from '@flogy/gatsby-theme-fgs-layout';
import useThemeOptions from '@flogy/gatsby-theme-fgs-legal/src/hooks/useThemeOptions';
import cookieConsentStyles from './cookie-consent-styles';

export const CookieConsentRootWrapper = ({ children }) => {
  const themeOptions = useThemeOptions();

  if (!themeOptions.cookieBanner.enable) {
    return children;
  }

  return (
    <>
      <CookieConsent
        buttonText="OK, I agree"
        cookieName="user-accepted-cookies"
        sameSite="strict"
        {...cookieConsentStyles}
      >
        To offer you the best possible functionality this website uses cookies for the purposes described in the{' '}
        <Link href={themeOptions.dataPrivacy.path}>data privacy</Link>. By using this website you agree with that.
      </CookieConsent>
      {children}
    </>
  );
};
